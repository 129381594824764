import {
  createElement,
  FC,
  FunctionComponent,
  useEffect,
  useRef,
  useState
} from 'react';
import { BehaviorSubjectSubscribe } from 'packages/behavior-subject';

function useSubscribe(subscribe: BehaviorSubjectSubscribe<any>) {
  const initialSubscribeRef = useRef(subscribe);
  const [state, setState] = useState(() => {
    // Grab value if it exists so initial state has it already set before effect.
    let initialValue: any;
    const unsubscribe = subscribe((value) => {
      initialValue = value;
    });
    unsubscribe();
    return initialValue;
  });

  useEffect(() => {
    return subscribe((value) => {
      if (value !== state) {
        // value could be function so use function setter in setState
        // to ensure value is set to state.
        setState(() => value);
      }
    });
  }, [setState, subscribe, state]);

  if (initialSubscribeRef.current !== subscribe) {
    throw new Error(
      'subscribe instance is not allowed to be changed from initial'
    );
  }

  return state;
}

interface Props {
  Provider: FC<{ value: any }> | FunctionComponent<{ value: any }>;
  subscribe: BehaviorSubjectSubscribe<any>;
}

export const ConnectProviderToSubscription: FC<Props> = ({
  children,
  subscribe,
  Provider
}) => {
  const value = useSubscribe(subscribe);

  if (value === null || value === undefined) {
    return null;
  }

  return <Provider value={value}>{children}</Provider>;
};
